<template>
  <v-btn
    v-if="isEditAccess"
    icon
    small
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon small>mdi-lead-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'RccRowEditButton',

  inheritAttrs: false,

  computed: {
    isEditAccess() {
      return !this.$route.meta.editAccess || this.$route.meta.editAccess.includes(this.$store.user.role)
    }
  }
}
</script>
